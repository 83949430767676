.password-reset-page {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  font-size: 14px !important;

  .container {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .password-reset-container {
      padding: 40px 0 !important;
      width: 100%;
    }
  }

  footer {
    flex-shrink: 0;
  }

  .logo {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;

    > img {
      margin: 0 auto;
      max-width: 65%;
    }
  }

  #reset-password-form {

    .password-container {

      #password {
        border-right: 0;
      }

      .input-group-btn {
        > .btn {
          background: transparent;
          border: 1px solid #e5e9ec;
          border-left: 0;
          box-shadow: none !important;
        }
      }

      .form-control {
        min-height: 38px;
      }
    }

    #requirements {
      margin-bottom: 30px;
      font-size: 90%;

      > .requirement {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > i {
          margin-right: 6px;
        }

        &.valid {
          color: #198754;
        }
      }
    }
  }
}
.password-forgot-page {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  font-size: 14px !important;

  .container {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .password-forgot-container {
      width: 100%;
      padding: 40px 0 !important;
    }
  }

  footer {
    flex-shrink: 0;
  }
}
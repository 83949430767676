.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  font-size: 14px !important;

  .container {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  footer {
    flex-shrink: 0;
  }

  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    width: 100%;

    @media screen and (max-width: 767.99px) {
      flex-direction: column;
    }

    > div[class*="col-"]{
      border-right: 0 !important;
    }

    #LogoCRM {
      img {
        margin: 0 auto;
      }
    }

    .right-block {
      .password-forgot {
        padding-left: 0;
        padding-right: 0;

        &:hover {
          color: #5e5e5e !important;
        }
      }
    }

    @media screen and (min-width: 767.99px) {
      .right-block {
        border-left: 1px solid #ddd;
      }
    }

    @media screen and (min-width: 991.99px) {
      #LogoCRM {
        img {
          width: 100%;
        }
      }


    }
  }
}
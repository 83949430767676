.bg-white {
  background-color: white;
}

.text-danger {
  color: #dc3545 !important;
}

.error {
  margin-top: 10px;
}

.overflow-hidden {
  overflow: hidden;
}

#privacy-policy {
  .alert-privacy-policy {
    position: fixed;
    left: 20px;
    bottom: 50%;
    transform: translate(0%, 50%);
    width: calc(100% - 40px);
    height: calc(100vh - 60px);
    margin-bottom: 0;
    z-index: 9999;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 0;
    background-color: white;
    color: black;
    padding: 0px;
    font-size: 14px;
    display: none;
    flex-direction: column;

    &.show {
      display: flex !important;
    }

    .close {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: end;
      padding: 25px !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") !important;
      background-size: 16px !important;
      background-position: 50% !important;
      position: relative !important;
      top: 0;
      right: 0;
      width: 1rem !important;
      height: 1rem !important;
      z-index: 2;
    }

    @media screen and (min-width: 992px) {
      width: 75%;
      left: 50%;
      transform: translate(-50%, 50%);
    }

    .alert-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 25px;
    }

    .alert-body {
      //max-height: calc(100vh - 200px);
      overflow-y: auto;
      scrollbar-color: rgb(0, 0, 0) rgb(255, 255, 255);

      > div {
        height: 50%;
        overflow-y: scroll;
        border-top: 2px solid;
        border-bottom: 2px solid;

        & + div {
          border-top: none;
        }

        .accept-privacy, .accept-terms {
          display: flex;
          align-items: flex-start;
          padding: 10px 30px 25px 30px;
          position: sticky;
          bottom: -1px;
          background-color: white;

          &:before {
            background-image: linear-gradient(360deg, #fff, hsla(0, 0%, 100%, 0));
            content: "";
            display: block;
            height: 60px;
            top: -60px;
            position: absolute;
            width: 100%;
            left: 0;
          }

          input[type="checkbox"] {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;

            &:checked {
              + label {
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  top: 2px;
                  left: 7px;
                  width: 6px;
                  height: 13px;
                  border: solid #0079bf;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                }
              }
            }

            &:disabled {
              + label {
                opacity: .5;
                cursor: default;
              }
            }
          }

          label {
            cursor: pointer;
            position: relative;
            margin-bottom: 0;
            display: flex;
            font-weight: bold;
            user-select: none;
            gap: .75ex;

            &:before {
              content: '';
              -webkit-appearance: none;
              background-color: transparent;
              border: solid 1px #e1e3e6;
              border-radius: 3px;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
              padding: 9px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .alert-footer {
      display: flex;
      padding: 15px 25px;
      border-top: 1px solid #e6e6e6;
      gap: 10px;
      justify-content: flex-end;
    }
  }

  .privacy-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9990;
    width: 100vw;
    height: 100vh;
    background-color: #000;

    &.show {
      opacity: .5;
    }

    &.hide {
      opacity: 0;
    }
  }
}

footer {
  background-color: #274075;
  color: white;
  padding: 10px 20px;
  //position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;

  .links {
    margin-bottom: 10px;

    .link {
      font-weight: 900;
    }
  }

  .small {
    font-size: 11px;
    font-weight: lighter;
    letter-spacing: 1px;
    display: block;
    width: 100% !important;
    line-height: 16px;

    strong {
      font-weight: 900;
    }
  }

  a {
    color: white;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
      color: white;
    }
  }
}

#iubenda_policy.iubenda_fluid_policy {
  .iub_container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}